<template>
  <div class="wrapper">
    <div class="headerWrapper">
      <h3 class="header">The Specs</h3>
      <div>
        <div>
          <p class="subheader--bold">Photos and Measurements</p>
        </div>

        <p class="description">While it’s not required, we <span class="emphasized">highly recommend</span> uploading
          images and measurements of each room to ensure proper fit.
          Below, you’ll find two options to provide a floorplan and images.</p>
      </div>
    </div>
    <div class="rendrSectionWrapper">
      <div class="rendrSectionDescriptionWrapper">
        <div class="rendrSectionDescriptionWrapperInner"><p class="rendrSectionTitle">Option 1: <a class="rendrLink" href="https://rendr.com/" target="_blank">RENDR room scan</a></p>
          <p class="recommended">Recommended</p></div>
        <p class="compatibleWithDevices">Compatible with: iPhone 12 - 15 Pro / Max, iPad (2020 and later)</p>
      </div>
      <div class="qrCodeWrapper">
        <img src="../../../assets/images/rendr-logo.svg" alt="Rendr logo"/>
        <a class="scanButton rendrCta"
           :href="`${baseAppUrl}/scan-with-rendr?user=${currentUser.uid}&survey=${targetSurvey.id}`" target="_blank">Scan
          your room with RENDR!</a>
        <div class="qrCode">
          <VueQRCodeComponent :text="`${baseAppUrl}/scan-with-rendr?user=${currentUser.uid}&survey=${targetSurvey.id}`"
                              size="160"></VueQRCodeComponent>
          <p class="qrCodeDescription">Scan to get started with RENDR app</p>
        </div>
      </div>
    </div>
    <p class="sectionTitle">Option 2: Upload floorplans and images</p>
    <p class="compatibleWithDevices">Great furniture isn’t so great if it doesn’t fit, so we’ll need 4-6 photos and
      accurate measurements of each room.</p>
    <div class="fileUploadContainer">
      <div class="fileUploadWrapper">
      <SquareFileUpload title="Photos"
                        :idleLabel="`<div class='labelIdleWrapper'><img src='./img/icons/media-icon.svg' class='uploadIcon' alt='Upload Icon'><p class='labelIdleText'>The more images the better! Please make sure to provide a minimum of all four walls. (Prefer shooting video instead? That works!)</p><button class='labelIdleButton'>Upload media</button></div>`"
                        v-model="roomPhotos" ref="roomPhotosUploader"/>
      </div>
      <div class="fileUploadWrapper">
      <SquareFileUpload title="Floor Plan"
                        :idleLabel="`<div class='labelIdleWrapper'><img src='./img/icons/floor-plan.svg' class='uploadIcon' alt='Upload Icon'><p class='labelIdleText'>Have a floorplan? Prefer to make one? Make sure the measurements are accurate and then upload!</p><a id='RoomMeasurementsExample'>See examples</a><button class='labelIdleButton'>Upload floorplan</button></div>`"
                        :handleFilePondInit="handleFilePondInit" v-model="floorPlans" ref="floorPlansUploader"/>
      </div>
    </div>
  </div>
  <PhotosPreview :images="roomPhotos?.concat(floorPlans)" :onFileRemove="(file) => removeFile(file.id)"/>
  <ExamplesModal :onClose="closeExamplesModal" v-if="showExamplesModal"/>
  <ValidationWarning :valid="!errorText" :text="errorText"/>
</template>

<script>
import SquareFileUpload from '@/components/quiz/PhotosAndMeasurements/components/SquareFileUpload.vue'
import ExamplesModal from '@/components/quiz/PhotosAndMeasurements/components/ExamplesModal.vue'
import PhotosPreview from '@/views/PhotosPreview.vue'
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'
import { mapGetters, mapState } from 'vuex'
// import { httpsCallable } from 'firebase/functions'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidationWarning, PhotosPreview, ExamplesModal, SquareFileUpload },
  props: {
    rendrInvitationId: {
      type: String
    },
    modelValue: {
      type: Object
    },
    responses: {
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'targetSurvey'
    ]),
    ...mapState(['fbFunctions']),
    baseAppUrl: function () {
      return process.env.VUE_APP_ROOT_URL
    }
  },
  data: () => {
    return {
      showExamplesModal: false,
      roomPhotos: [],
      floorPlans: [],
      inputValue: {
        room_photos: [],
        floor_plans: []
      },
      errorText: null
    }
  },
  methods: {
    closeExamplesModal () {
      this.showExamplesModal = false
    },
    openExamplesModal () {
      this.showExamplesModal = true
    },
    handleFilePondInit () {
      const element = document.querySelector('#RoomMeasurementsExample')
      element.addEventListener('click', (e) => {
        e.preventDefault()
        this.openExamplesModal()
      })
    },
    removeFile (file) {
      if (this.roomPhotos.includes(file)) {
        this.$refs.roomPhotosUploader.remove(undefined, file)
      } else if (this.floorPlans.includes(file)) {
        this.$refs.floorPlansUploader.remove(undefined, file)
      }
    },
    async validate () {
      if (this.floorPlans.some(img => !img.url) || this.roomPhotos.some(img => !img.url)) {
        this.errorText = 'Please wait for your images to upload before proceeding'
        return false
      }

      return true
    }
  },
  async mounted () {
    this.inputValue = this.modelValue
  },
  watch: {
    roomPhotos: {
      handler (val) {
        this.inputValue.room_photos = val
      }
    },
    floorPlans: {
      handler (val) {
        this.inputValue.user_floor_plans = val
      }
    }
  }
}
</script>

<style lang="scss">
.wrapper {
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

.headerWrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
}

.header {
  font-family: "League Spartan", sans-serif;
  font-size: 44px;
  font-weight: 600;
  color: $tt-dark-text
}

.subheader {
  font-family: Sohne, serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;

  &--bold {
    font-family: Sohne, serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 33px;
  }
}

.fileUploadWrapper {
  width: 100%;
  @media (max-width: 1024px) {
    background: #EFF7F3;
    padding: 16px;
    border-radius: 16px;
  }
}

.description {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $tt-dark-text;
  margin-top: 12px;
}

.emphasized {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $tt-dark-green;
}

.fileUploadContainer {
  display: flex;
  gap: 18px;
  margin-bottom: 24px;
  width: 100%;
  margin-top: 40px;
  background: #EFF7F3;
  border-radius: 16px;
  padding: 24px;

  @media (max-width: 1024px) {
    //flex-direction: column;
    background: transparent;
    gap: 16px;
  }
}

.labelIdleText {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.75rem !important;
  }
}

.uploadIcon {
  margin: 0 !important
}

.rendrSectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.rendrSectionTitle {
  font-family: Sohne, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.93px;
  color: $tt-dark-text;
}

.sectionTitle {
  align-self: start;
  font-family: Sohne, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.93px;
  color: $tt-dark-text;
  text-align: center;
  width: 100% !important;
  margin-bottom: 20px;
}

.rendrSectionDescriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &Inner {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 12px;
    }
  }
}

.recommended {
  padding: 5px 12px 5px 12px;
  gap: 20px;
  border-radius: 16px;
  background-color: #F6FF9380;
  font-family: "Sohne", serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.38px;
  text-align: left;
}

.qrCodeWrapper {
  display: flex;
  gap: 120px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #EFF7F3;
  border-radius: 16px;
  padding: 24px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
}

.rendrLink {
  font-family: "Sohne", serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.48px;
  text-decoration: underline;
}

.qrCode {
  pointer-events: none;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
}

.qrCodeDescription {
  font-family: "Sohne", serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.29px;
  text-align: center;
}

.compatibleWithDescription {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.38px;
  text-align: left;
  margin-bottom: 8px;
  color: $tt-dark-text;
}

.compatibleWithDevices {
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.38px;
  text-align: left;
  color: $tt-dark-text;
}

.scanButton {
  font-family: Sohne, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: $tt-dark-green;
  border: 1px solid $tt-dark-green !important;
  text-decoration: none !important;
  border-radius: 40px;
  height: fit-content;
  padding: 20px 40px;
  cursor: pointer;
  background-color: #F6FF93;
  text-align: center;

  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
