<template>
  <div class="progress-bar">
    <div class="progress-line" :style="{ width: progressWidth }"></div>
    <div class="checkpoints">
      <div v-for="(label, index) in labels" :key="index" class="checkpoint" :style="{ left: `${(index / (labels.length - 1)) * 100}%` }">
        <span class="label">{{ label }}</span>
        <div class="dot" :class="{ 'reached': index <= reachedCheckpoints}"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true
    },
    reachedCheckpoints: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressWidth () {
      const percent = (this.reachedCheckpoints / (this.labels.length - 1)) * 100
      return percent + '%'
    }
  }
}
</script>

<style scoped lang="scss">
.progress-bar {
  width: 70%;
  margin: 0 auto;
  height: 2px;
  position: relative;
  background-color: #0B13264D;
}

.progress-line {
  background-color: $tt-brand-green;
  height: 2px;
  position: absolute;
  left: 0;
  width: 50%;
}

.checkpoints {
  position: relative;
}

.checkpoint {
  position: absolute;
  top: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: $main-bg;
  border: 2px solid #B6B8BE;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  &.reached {
    background-color: $tt-brand-green;
    border: none;
  }
}

.reached {
  background-color: $tt-brand-green;
}

.label {
  color: black;
  font-size: 10px;
  position: absolute;
  top: -20px;
  white-space: nowrap;
}
</style>
