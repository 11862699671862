<!--
  This component serves as a wrapper around quiz questions, to account for common UI elements
-->
<template>
  <form class="form form_upload_image" @submit.prevent="nextStepEvent">
    <div class="grid__inner">
      <slot />
      <div class="quiz__navigation navigation-handle" v-if="!hideNavigation">
        <button
          @click="prevStep"
          class="quiz__navigation__button quiz__navigation__button--back"
          :disabled="prevBtnDisabled"
        >
          <span class="icon-arrow-left"></span> Back
        </button>
        <div >
          <button
            :disabled="continueBtnDisabled"
            @click="nextStep"
            class="quiz__navigation__button quiz__navigation__button--continue primary"
          >
            {{ lastStep ? 'Done' : 'Continue' }}
            <span v-if='!lastStep' class="icon-arrow-right"></span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'QuizStepWrapper',
  props: {
    sectionHeading: {
      type: String,
      default: 'Step Heading'
    },
    index: {
      type: Number,
      default: 0
    },
    nextStep: {
      type: Function
    },
    prevStep: {
      type: Function
    },
    lastStep: {
      type: Boolean,
      default: false
    },
    continueBtnDisabled: {
      type: Boolean,
      default: false
    },
    prevBtnDisabled: {
      type: Boolean,
      default: false
    },
    hideNavigation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modelValue: {
        type: Object,
        default: () => {}
      }
    }
  },
  methods: {
    nextStepEvent () {
      this.$emit('next')
    }
  }
}
</script>
