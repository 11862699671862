<template>
  <Modal class="" @close="onClose" :hideActions="true">
    <template v-slot:body>
    <div class="wrapper">
      <div>
        <h3 class="heading">Example A: Make your own floor plan</h3>
        <div  class="section">
          <img class="img" src="../../../../assets/images/floor-plan-examples/example-A.png" alt="Floor plan example 1"/>
          <div>
            <p class="paragraph">1. Make sure to include measurements of built-in features like fireplaces, counters, permanent
              obstructions, etc.</p>
            <p class="paragraph">2. Don’t forget measurements between wall features: doors, windows, etc..</p>
            <p class="paragraph">3. Measure openings of new door locations.</p>
          </div>
        </div>
      </div>
      <div >
        <h3 class="heading">Example B: Pro floor plan</h3>
        <h4 class="subheading">
          Provided by owner, management, etc. Make sure it includes:
        </h4>
        <div class="section">
          <img class="img" src="../../../../assets/images/floor-plan-examples/example-B.png" alt="Floor plan example 1"/>
          <div>
            <p class="paragraph">1. Make sure to include measurements of built-in features like fireplaces, counters, permanent
              obstructions, etc.</p>
            <p class="paragraph">2. Don’t forget measurements between wall features: doors, windows, etc.</p>
            <p class="paragraph">3. Measure openings of new door locations.</p>
          </div>
        </div>
      </div>
    </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'ExamplesModal',
  components: {
    Modal
  },
  props: {
    onClose: {
      type: Function
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}
.heading {
  font-family: Sohne,serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  color: $tt-dark-text;
  width: 100%;
  text-align: center;
}
.subheading {
  font-family: Sohne,serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: $tt-dark-text;
  width: 100%;
  text-align: center;
}
.paragraph {
  font-family: Sohne,serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: $tt-dark-text;
}
.section {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}
.img {
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
</style>
