<template>
  <div v-if="adaptedFiles && adaptedFiles.length > 0"
       class="cudPHoto">
    <div class="fvdfg">
      <div v-for="(file) in adaptedFiles" :key="file.url" class="PhotoItem">
        <img v-if="file.url" :src="file.url" alt="Uploaded Image">
        <div v-else class="placeholder"></div>
        <button class="PhotoItem--close-btn" @click="onFileRemove(file)"><img
            src="@/assets/images/close-no-bg_new.svg" class="PhotoItem--close-icon" alt="Close Icon"></button>
      </div>

    </div>
  </div>
</template>
<script>

const imageExtensionRegex = new RegExp(/(.*)(\.png|.jpg|.jpeg|.avif|.gif|.svg)/i)
export default {
  name: 'PhotosPreview',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    onFileRemove: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    adaptedFiles () {
      return this.images.map((image) => {
        if (image.url && !imageExtensionRegex.test(image.url)) { return { ...image, url: image.url.replace(/\.\w+$/, '.jpg') } }
        return image
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.placeholder{
  background-color: rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
}

</style>
