<template>
  <!-- <fieldset class="form__upload upload_box"> -->
      <div class="filepondWrapper">
        <!-- file-pond events are handled with @ on attributes -->
        <p class="title">{{title}}</p>
        <file-pond
          name="file"
          ref="pond"
          :label-idle="idleLabel"
          allow-multiple
          :maxFiles="maxFiles ? maxFiles : undefined"
          credits="false"
          :server="{process: process, revert: revert}"
          :files="files"
          @init="handleFilePondInit"
          @warning="uploadWarning($event)"
          @processfiles="updateValue"
          @updatefiles="updateValue"
        />
        <ValidationWarning :valid="valid" :text="errorText" />
      </div>
</template>

<script>
import { mapState } from 'vuex'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import { blobToDataURI } from '@/assets/scripts/helpers'
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'
import { makeDeleteRequest, makeUploadRequest } from '@/utils/cloudinary/cloudinaryHelper'
// import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType
  // FilePondPluginImagePreview
)

export default {
  name: 'SquareFileUpload',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Number,
      default: 0
    },
    maxFiles: {
      type: Number,
      default: null
    },
    // The maximum size in pixels for both the image's width and height
    maxImageSize: {
      type: Number,
      default: 1920
    },
    title: {
      type: String,
      default: ''
    },
    idleLabel: {
      type: String,
      default: '<img src=\'./img/upload-icon.png\' class=\'upload-icon\' alt=\'Upload Icon\'> Drag and drop images here, or <span class=\'browse-text\'>browse to upload</span>'
    },
    handleFilePondInit: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      valid: true,
      output: [],
      defaultErrorText: `Please upload at least ${this.required} files.`,
      errorText: '',
      files: []
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  mounted () {
    this.errorText = this.defaultErrorText
    this.output = this.modelValue || []
  },
  methods: {
    validate () {
      this.valid = !(this.required && this.$refs?.pond?.getFiles().length < this.required)
      return this.valid
    },
    uploadWarning (event) {
      if (event?.body === 'Max files') {
        this.valid = false
        this.errorText = `Please upload between ${this.required} and ${this.maxFiles} files.`
      }
    },
    resetError () {
      this.errorText = this.defaultErrorText
      this.valid = true
    },

    setError (message) {
      this.valid = false
      this.errorText = message
    },

    /**
     * When a file is removed, there are a number of things that need to be done...
     */
    async remove (error, file) {
      if (!this.$refs.pond) return

      this.resetError()
      if (error) {
        return
      }
      this.$refs.pond.removeFile(file.id, { revert: true })

      await this.updateValue()
    },
    /**
     * Called when an image is added, used to update the modelValue,
     * so the image data is passed up to the parent.
     */
    async updateValue () {
      if (!this.$refs.pond) return
      this.resetError()
      const files = this.$refs.pond.getFiles()
      const output = []
      for (const i in files) {
        const fileData = {
          id: files[i]?.serverId,
          url: files[i]?.source.url
        }
        output.push(fileData)
      }

      this.output = output
      this.$emit('update:modelValue', this.output)
    },

    // fileUploadMethods
    revert (token, successCallback, errorCallback) {
      makeDeleteRequest({
        token,
        successCallback,
        errorCallback
      })
    },

    process  (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      transfer,
      _options
    ) {
      const abortRequest = makeUploadRequest({
        file,
        fieldName,
        successCallback: load,
        errorCallback: error,
        progressCallback: progress
      })

      return {
        abort: () => {
          abortRequest()
          abort()
        }
      }
    }
  },
  components: {
    ValidationWarning,
    FilePond
  }
}
</script>

<style lang="scss">
.filepondWrapper {
  width: 100%;
  min-width: 150px;
  & .filepond--root {
    height: 320px !important;
    border: none;
    background: #FFFFFF80 !important;
  }
}

.filepond--root {
  @include meta-text;
  border: 2px dashed rgb(0 90 36 / 20%);
  border-radius: 16px;

  .filepond--file-info,
  .filepond--file-status {
    font-size: .75rem;
    line-height: var(--leading);
    letter-spacing: .07em;

    * {
      font-size: .75rem;
      line-height: var(--leading);
      letter-spacing: .07em;
    }
  }

  .filepond--file-action-button {
    cursor: pointer;
  }

  /* the text color of the drop label*/
  .filepond--drop-label {
    color: $tt-green;
  }

  /* underline color for "Browse" button */
  .filepond--label-action {
    text-decoration-color: $tt-green;
  }

  /* the background color of the filepond drop area */
  .filepond--panel-root {
    background-color: $brand-secondary;
  }

  /* the border radius of the drop area */
  .filepond--panel-root,
  .filepond--item-panel,
  .filepond--file,
  .filepond--image-preview-wrapper {
    border-radius: 0;
  }
  .filepond--list-scroller {
    display: none;
  }

  /* the background color of the file and file panel (used when dropping an image) */
  .filepond--item-panel {
    background-color: $tt-green;
  }

  /* the background color of the drop circle */
  .filepond--drip-blob {
    background-color: $brand-secondary;
  }

  /* the background color of the black action buttons */
  .filepond--file-action-button {
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* the icon color of the black action buttons */
  .filepond--file-action-button {
    color: white;
  }

  /* the color of the focus ring */
  .filepond--file-action-button:hover,
  .filepond--file-action-button:focus {
    box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
  }

  /* the text color of the file status and info labels */
  .filepond--file {
    color: white;
  }

  /* error state color */
  [data-filepond-item-state*='error'] .filepond--item-panel,
  [data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: red;
  }

  [data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: green;
  }

  /* bordered drop area */
  .filepond--panel-root {
    background-color: $brand-secondary;
  }

  .filepond--image-preview {
    background: $secondary-bg;
  }
}

.title {
  width: 100%;
  text-align: center;
  font-family: Söhne,serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 12px;
  color: $tt-dark-text;
}
</style>
