<template>
  <div  class="checkoutWrapper">
    <Loader :visible="loading" fixed/>
    <div id="checkout" ref="Checkout" class="checkout"></div>
    <PromoCodePopup/>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions'
import { mapGetters } from 'vuex'
import { loadStripe } from '@stripe/stripe-js/pure'
import Loader from '@/components/Loader.vue'
import PromoCodePopup from '@/components/quiz/PromoCodePopup.vue'

const cloudFunction = getFunctions()

export default {
  components: { PromoCodePopup, Loader },
  computed: {
    ...mapGetters(['targetSurvey', 'userProfile']),
    userSelectionFeeStatus () {
      return this.userProfile.selectionFeeOrderStatus
    }
  },
  props: {
    nextStep: {
      type: Function
    }
  },
  data () {
    return {
      checkout: null,
      loading: false
    }
  },
  async mounted () {
    if (this.userSelectionFeeStatus === 'complete') {
      this.nextStep()
      return
    }
    this.loading = true
    await this.initialize()
    if (this.checkout) {
      this.checkout.mount('#checkout')
    }
    this.loading = false
  },
  async unmounted () {
    if (this.checkout) {
      this.checkout.destroy()
    }
  },
  watch: {
    userSelectionFeeStatus (newVal) {
      if (newVal === 'complete') {
        this.nextStep()
      }
    }
  },
  methods: {
    async initialize () {
      if (this.checkout) return

      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)

      const result = await httpsCallable(cloudFunction, 'createSelectionFeeOrder')({
        surveyId: this.targetSurvey.id,
        paymentType: 'selection-fee',
        returnUrl: '/quiz',
        ui_mode: 'embedded'
      })

      this.checkout = await stripe.initEmbeddedCheckout({
        clientSecret: result.data.client_secret,
        onComplete: () => {
          if (this.$gtm?.enabled()) {
            this.$gtm.trackEvent({
              event: 'Selection fee payment',
              category: 'Payment',
              action: 'click',
              value: result.data?.amount_total
            })
          }
          this.nextStep()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.checkoutWrapper {
  grid-column-start: 3;
  grid-column-end: 11;
  display: flex;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: rgb(0, 64, 33);
  border-radius: 16px;
  height: 100%;
  min-height: 400px;
  @media (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 13;
    padding: 8px;
    margin-bottom: 8px;
  }
}
.checkout {
  width: 100%;
}
</style>
